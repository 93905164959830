.cursor {
  &-pointer {
    cursor: pointer;
  }
  &-not-allowed {
    cursor: not-allowed;
  }
  &-default {
    cursor: default;
  }
  &-grab {
    cursor: grabbing;
  }
  &-copy {
    cursor: copy;
  }
}
