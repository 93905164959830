.b {
  &-dropdown {
    border: 1px solid $dropdown-border-color;
  }

  &-r {
    &-regular {
      border-radius: 5px;
    }

    &-3 {
      border-radius: 3px;
    }

    &-0 {
      &-important {
        border-radius: 0 !important;
      }
    }
  }

  &-primary {
    border: 1px solid $primary-color;

    &-b {
      border-bottom: 1px solid $primary-color;
    }
  }

  &-danger {
    border: 1px solid red;
  }

  &-muted-1 {
    border: 1px solid $border-muted-wrapper;

    &-t {
      border-top: 1px solid $border-muted-wrapper;
    }

    &-b {
      border-bottom: 1px solid $border-muted-wrapper;
    }

    &-l {
      border-left: 1px solid $border-muted-wrapper;
    }

    &-r {
      border-right: 1px solid $border-muted-wrapper;
    }
  }

  &-muted {
    border: 1px solid $border-muted;

    &-top {
      border-top: 1px solid $border-muted;
    }

    &-bottom {
      border-bottom: 1px solid $border-muted;
    }
  }

  &-black {
    border: 1px solid $black-primary-color;

    &-top {
      border-top: 1px solid $black-primary-color;
    }

    &-bottom {
      border-bottom: 1px solid $black-primary-color;
    }
  }

  &-grey {
    border: 1px solid $border-grey;

    &-bottom {
      border-bottom: 1px solid $border-grey;
    }
  }

  &-light {
    border: 1px solid $border-light;

    &-bottom {
      border-bottom: 1px solid $border-light;
    }
  }

  &-0 {
    &-important {
      border: 0px !important;
    }

    &-r {
      border-right: 0px;
    }

    &-l {
      border-left: 0px;
    }
  }
}
