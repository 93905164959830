$limit: 50;
$directions-map: (
  t: "top",
  r: "right",
  b: "bottom",
  l: "left",
  x: (
    "left",
    "right",
  ),
  y: (
    "top",
    "bottom",
  ),
  a: (
    "top",
    "right",
    "bottom",
    "left",
  ),
);

@mixin spacing-generator($start, $attribute, $shorthand, $directions) {
  @for $i from $start through $limit {
    &#{"#{$shorthand}-#{$i}" } {
      @each $direction in $directions {
        #{$attribute}-#{$direction}: #{$i}px;
      }
    }
  }
}

.m {
  @each $shorthand, $directions in $directions-map {
    @include spacing-generator(-10, "margin", $shorthand, $directions);
  }
}

.p {
  @each $shorthand, $directions in $directions-map {
    @include spacing-generator(0, "padding", $shorthand, $directions);
  }
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

// extra classes used
.pl-60 {
  padding-left: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pl-100 {
  padding-left: 100px;
}

.ml-70 {
  margin-left: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.px-65 {
  padding-left: 65px;
  padding-right: 65px;
}
