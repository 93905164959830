.color {
  &-accent {
    color: $primary-color;

    &-hover {
      &:hover {
        color: $primary-color;
      }
    }
  }

  &-regular {
    color: $black-primary-color;
  }

  &-light {
    color: $light-color;
  }

  &-white {
    color: $white-color;
  }

  &-faded {
    color: $faded-color;
  }

  &-muted {
    color: $muted-color;
  }

  &-white {
    color: $white-color;
  }

  &-black-faded {
    color: $black-text-faded-color;
  }

  &-grey {
    color: $grey-color;
  }
  &-text-grey {
    color: $text-grey;
  }

  &-alertred {
    color: $alert-red;
  }

  &-green {
    color: $survey-status-background-live;
  }

  &-danger {
    color: red;
  }

  &-black {
    color: #000;
  }
  &-light-grey {
    color: $light-grey;
  }

  &-dark-grey {
    color: $dark-grey;
  }

  &-medium-grey {
    color: $medium-grey;
  }

  &-on_track {
    color: $status-track;
  }
  &-at_risk {
    color: $status-risk;
  }
  &-behind {
    color: $status-behind;
  }
  &-not_started {
    color: $status-not-started;
  }
}
.text {
  &-noselect {
    user-select: none;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-capitalize {
    text-transform: capitalize;
  }
  &-center {
    text-align: center;
  }
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-underline {
    text-decoration: underline;
  }
  &-italic {
    font-style: italic;
  }

  &-align {
    &-center {
      text-align: center;
    }
  }
}

.fs {
  &-xhuge {
    font-size: 24px;
  }

  &-huge {
    font-size: 22px;
  }

  &-plus {
    font-size: 20px;
  }

  &-regular {
    font-size: 18px;
  }

  &-default {
    font-size: 16px;
  }

  &-sdefault {
    font-size: 15px;
  }

  &-medium {
    font-size: 14px;
  }

  &-smedium {
    font-size: 13px;
  }

  &-small {
    font-size: 12px;
  }

  &-very-small {
    font-size: 11px;
  }

  &-tiny {
    font-size: 10px;
  }

  &-very-tiny {
    font-size: 9px;
  }
  &-miniscule {
    font-size: 8px;
  }
}
.fw {
  &-300 {
    font-weight: 300;
  }
  &-400 {
    font-weight: 400;
  }

  &-500 {
    font-weight: 500;
  }

  &-600 {
    font-weight: 600;
  }

  &-700 {
    font-weight: 700;
  }

  &-800 {
    font-weight: 800;
  }

  &-bold {
    font-weight: bold;
  }
}
.white-space {
  &-nowrap {
    white-space: nowrap;
  }
  &-prewrap {
    white-space: pre-wrap;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.lh {
  &-initial {
    line-height: initial;
  }
}

.text-titlecase {
  text-transform: capitalize;
}

.overflow-wrap {
  &-anywhere {
    overflow-wrap: anywhere;
  }
}
